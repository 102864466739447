import React from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import { getSubAppContainer } from '@/utils/mfe';

import theme from './theme';

function AppThemeProvider({ children }: { children: React.ReactNode }) {
  const themeProvider = <ThemeProvider theme={theme}>{children}</ThemeProvider>;

  if (window.__POWERED_BY_QIANKUN__) {
    return (
      <StyleSheetManager disableCSSOMInjection target={getSubAppContainer()}>
        {themeProvider}
      </StyleSheetManager>
    );
  }

  return themeProvider;
}

export default AppThemeProvider;
