import React, { Component } from 'react';

import ChunkLoadErrorFallback from '@/components/error-boundary/error-fallback/chunk-load-error-fallback';
import GeneralErrorFallback from '@/components/error-boundary/error-fallback/general-error-fallback';

class CatchAllErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    if (error?.name === 'ChunkLoadError') {
      console.error('ChunkLoadError caught:', error, errorInfo);
    } else {
      console.error('Error caught:', error, errorInfo);
    }
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      if (error?.name === 'ChunkLoadError') {
        return <ChunkLoadErrorFallback />;
      }

      if (process.env.NODE_ENV === 'development') {
        return <GeneralErrorFallback />;
      }
    }

    return children;
  }
}

export default CatchAllErrorBoundary;
