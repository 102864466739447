import React from 'react';
import styled from 'styled-components';

import { Label } from '@/components/Label';
import errorImage from '@/assets/ghost-404.svg';

function GeneralErrorFallback({ className }) {
  return (
    <div className={className}>
      <div className="error-container">
        <div className="left">
          <img src={errorImage} alt="error" />
        </div>
        <div className="right">
          <div className="row title">
            <Label lightGray fz20px semibold>
              Something went wrong!
            </Label>
          </div>
          <div className="row content">
            <Label lightGray fz13px>
              Please check your browser console for error details.
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
}

const StyledGeneralErrorFallback = styled(GeneralErrorFallback)`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f6fa;

  & .error-container {
    width: 30rem;
    height: 20rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    & .left {
      display: flex;
    }

    & .right {
      margin-left: 4rem;
      display: flex;
      flex-direction: column;

      & .title {
        font-size: 2rem;
      }

      & .content {
        font-size: 1rem;
      }

      & .row:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
`;

export default StyledGeneralErrorFallback;
