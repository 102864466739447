import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type Language = 'en' | 'es';

interface UseTranslationOptions {
  /** default value is the global resolved language */
  language?: Language;
}

/**
 * This is a special hook that returns the fixed t functions for en and es.
 * Use this hook when you need to translate to a specific language.
 * @param options
 * @returns
 */
export const useFixedT = (options?: UseTranslationOptions) => {
  const { t, i18n, ...rest } = useTranslation();
  const { language = i18n.resolvedLanguage } = options || {};
  const enT = useMemo(() => i18n.getFixedT('en'), [i18n]);
  const esT = useMemo(() => i18n.getFixedT('es'), [i18n]);
  const fixedT = language === 'es' ? esT : enT;

  return {
    ...rest,
    i18n,
    // global t
    t,
    // translate to en
    enT,
    // translate to es
    esT,
    // translate to the specified language
    fixedT,
  };
};
