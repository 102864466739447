import styled, { css } from 'styled-components';
import { get } from 'lodash';

const HeaderLabel = styled.h2<{ center?: boolean }>`
  text-align: ${(props) => {
    return props.center ? 'center' : 'left';
  }};
  color: ${(props) => props.theme.legacyColors.gray90};
  font-size: 16px;
  font-weight: 300;
`;

/**
 * Color props: color; white; gray; blue; lightgray;
 * Font props: semibold; bold
 * Font size props: (pre-pend fz) e.g. fz18px
 * @deprecated
 */
const Label = styled.span<{
  colorInherit?: boolean;
  white?: boolean;
  gray?: boolean;
  blue?: boolean;
  lightGray?: boolean;
  gray8C8383?: boolean;
  grayC3C3C3?: boolean;
  silver?: boolean;
  green?: boolean;
  black1e?: boolean;
  black?: boolean;
  black6c?: boolean;
  semibold?: boolean;
  bold?: boolean;
  italic?: boolean;
  openSans?: boolean;
  openSansBold?: boolean;
  fontSize?: string;
  [fzKey: `fz${number}px`]: boolean;
  [fwKey: `fw${number}`]: boolean;
}>(
  (props) => css`
    color: ${() => {
      let color = props.theme.legacyColors ? props.theme.legacyColors.gray90 : '#666666';

      if (props.color) {
        color = get(props.theme.legacyColors, props.color, props.color);
      } else if (props.colorInherit) color = 'inherit';
      else if (props.white) color = 'white';
      else if (props.gray) color = '#999999';
      else if (props.blue) color = '#0D8CE6';
      else if (props.lightGray) color = '#9E9FA0';
      else if (props.gray8C8383) color = '#8c8383';
      else if (props.grayC3C3C3) color = '#C3C3C3';
      else if (props.silver) color = '#b9c2cb';
      else if (props.green) color = '#2dc294';
      else if (props.black1e) color = '#1e1e1e';
      else if (props.black) color = '#060606';
      else if (props.black6c) color = '#6c6c6c';

      return color;
    }};
    font-family: ${() => {
      if (props.semibold) return 'Segoe UI';
      if (props.bold) return 'Segoe UI';
      if (props.openSansBold) return 'OpenSans';
      if (props.openSans) return 'OpenSans';
    }};
    font-size: ${() => {
      for (const k in props) {
        if (k && /^fz.+/.test(k)) {
          return k.match(/^fz(.+)/)?.[1];
        }
      }

      if (props.fontSize) {
        return props.fontSize;
      }

      return null;
    }};
    font-weight: ${() => {
      if (props.semibold) return '600';
      if (props.bold) return '700';
      if (props.openSansBold) return 'bold';
      if (props.openSans) return 'normal';
      let value = null;
      for (const k in props) {
        if (k && /^fw.+/.test(k)) {
          value = k.match(/^fw(.+)/)?.[1];
        }
      }
      return value;
    }};
    font-style: ${() => {
      return props.italic ? 'italic' : 'normal';
    }};
  `,
);

/**
 * Text align: center
 * Flex shrink: wrap
 * Vertical align middle: verticalMiddle
 * Vertical align inline middle: verticalInlineMiddle
 * @deprecated
 *
 * TODO: replace all usages of this with new Typography
 */
const TextLabel = styled(Label).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['wrap'].includes(prop) && defaultValidatorFn(prop),
})<{
  inline?: boolean;
  inlineBlock?: boolean;
  wrap?: boolean;
  center?: boolean;
  right?: boolean;
  verticalMiddle?: boolean;
  verticalInlineMiddle?: boolean;
  ellipsis?: boolean;
  ml?: string;
  mr?: string;
  mt?: string;
  mb?: string;
}>(
  (props) => css`
    display: ${() => {
      return props.inline ? 'inline' : props.inlineBlock ? 'inline-block' : 'block';
    }};
    clear: ${() => {
      return props.inlineBlock ? 'both' : 'inherit';
    }};
    float: ${() => {
      return props.inlineBlock ? 'left' : 'inherit';
    }};
    flex-shrink: ${() => {
      return props.wrap ? 1 : 0;
    }};
    white-space: ${() => {
      return props.wrap ? 'initial' : 'nowrap';
    }};
    word-break: ${() => {
      return props.wrap ? 'break-word' : 'normal';
    }};
    text-align: ${() => {
      return props.center ? 'center' : props.right ? 'right' : 'start';
    }};
    vertical-align: ${() => {
      return props.verticalMiddle ? 'middle' : 'baseline';
    }};
    margin-top: ${() => {
      return props.verticalInlineMiddle ? 'auto' : 0;
    }};
    margin-bottom: ${() => {
      return props.verticalInlineMiddle ? 'auto' : 0;
    }};
    margin: ${() => {
      let { ml = '0px', mr = '0px', mt = '0px', mb = '0px' } = props;
      if (`${mt}`.indexOf('px') === -1) mt = `${mt}px`;
      if (`${mr}`.indexOf('px') === -1) mr = `${mr}px`;
      if (`${mb}`.indexOf('px') === -1) mb = `${mb}px`;
      if (`${ml}`.indexOf('px') === -1) ml = `${ml}px`;
      // re-enforce mt,mr,mb,ml to be a specific value like: '3px' to keep
      // consistent with the rebass lib that accepting specific value to config
      // space.  Remove the inconsistency when:
      // <TextLabel ml={1} /> result in 1px but <Flex ml={1} /> result in 8px
      return `${mt} ${mr} ${mb} ${ml}`;
    }};
    overflow: ${props.ellipsis ? 'hidden' : 'inherit'};
    text-overflow: ${props.ellipsis ? 'ellipsis' : 'inherit'};
  `,
);

const LinkLabel = styled(TextLabel)`
  color: #0d8ce6;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
`;

const ButtonLabel = styled(TextLabel)<{ disabled?: boolean }>`
  user-select: none;
  color: ${(props) => {
    return props.disabled ? '#C3C3C3' : props.black ? props.theme.legacyColors.gray90 : '#0D8CE6';
  }};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    color: ${(props) => (props.disabled ? '#C3C3C3' : '#0D8CE6')};
  }
`;

const ErrorLabel = styled(TextLabel)`
  color: #fc1927;
`;

const MultipleLineLabel = styled(TextLabel)`
  white-space: pre-line;
`;

const GrayText = styled.span`
  background-color: #f2f3f9;
  color: #969696;
  padding: 5px 5px;
  border-radius: 3px;
  margin-top: 4px;
`;

const Title = styled(TextLabel)`
  font-size: 16px;
  font-weight: 800;
`;

const Subtitle = styled(TextLabel)`
  font-size: 12px;
  font-weight: 300;
  color: #9e9fa0;
`;

/**
 * @deprecated Use the NFW components
 */
export {
  HeaderLabel,
  Label,
  TextLabel,
  ButtonLabel,
  LinkLabel,
  ErrorLabel,
  MultipleLineLabel,
  GrayText,
  Title,
  Subtitle,
};
