import React, { Suspense } from 'react';
import type { TimelineWidgetProps } from '@helloworld1812/timeline-widget';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { TimelineWidget } from '@helloworld1812/timeline-widget';

import { useIsFeatureOn } from '@/hooks/useIsFeatureOn';

/**
 * Wraps the timeline widget component in a lazy import,
 * to reduce bundle size if needed.
 * Also adds suspense, error boundary, and feature flag to control
 * display.
 * @returns
 */
export const TimelineWidgetWrapper: React.FC<TimelineWidgetProps> = (timelineWidgetProps: TimelineWidgetProps) => {
  const timelineWidgetOn = useIsFeatureOn('timeline_widget_hr');

  const renderError = (props: FallbackProps) => {
    console.error('timeline-widget failed to render', props.error);
    return <div id="timeline-widget-error" />;
  };

  // Only render if feature flag is on.
  if (!timelineWidgetOn) {
    return null;
  }

  return (
    <ErrorBoundary fallbackRender={renderError}>
      <Suspense fallback={<div id="timeline-widget-loading" />}>
        <TimelineWidget {...timelineWidgetProps} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default TimelineWidgetWrapper;
